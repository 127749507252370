import {clearCacheExceptCustomer, getConfig, isNotNull} from "../Utils";
import {clearBasket} from "./Basket";
var mqtt = require('mqtt');

var app = getConfig();

var mqttConf = {
    host: "mqtt.rd.atosworldline.com",
    port: 8003,
    username: "demokit",
    password: "d3m0k1t",
    rejectUnauthorized: false,
    protocol: 'wss'
}

var demoId = localStorage.getItem("DKC_demoId");
var topicScanPoster;
var topicScanProduct;
var topicBackofficeApprove;
var topicBackofficeReject;
var topicPaymentDone;
var topicLaneSuccess;
var topicScanLane;
var topicSelectPayment;
var topicPaymentList;
var topicLaneSuccess;
var topicReset;
var client;
var initOk = false;

function initTopics(){
    if(isNotNull(demoId)){
        topicScanPoster = "wldd/demokit/dks/food/" + demoId + "/scanPoster";
        topicScanProduct = "wldd/demokit/dks/food/" + demoId + "/scanProduct";
        topicPaymentDone = "wldd/demokit/dks/food/" + demoId + "/paymentDone";
        topicBackofficeApprove = "wldd/demokit/dks/food/" + demoId + "/backofficeApprove";
        topicBackofficeReject = "wldd/demokit/dks/food/" + demoId + "/backofficeReject";
        topicScanLane = "wldd/demokit/dks/food/" + demoId + "/scanLane";
        topicSelectPayment = "wldd/demokit/dks/food/" + demoId + "/selectPayment";
        topicPaymentList = "wldd/demokit/dks/food/" + demoId + "/paymentList";
        topicLaneSuccess = "wldd/demokit/dks/food/" + demoId + "/laneSuccess";
        topicReset = "wldd/demokit/dks/food/" + demoId + "/resetDemo";
        initOk = true;
    }
}

export function publishMessage(type, message = "", callback = null) {
    initTopics();
    if(initOk){
        client = mqtt.connect({
            host: mqttConf.host,
            port: mqttConf.port,
            username: mqttConf.username,
            password: mqttConf.password,
            rejectUnauthorized: mqttConf.rejectUnauthorized,
            protocol: mqttConf.protocol
        });

        errorHandler(client);

        client.on('connect', function () {
            var topic = getTopic(type);
            if(isNotNull(callback)){
                client.publish(topic, message, callback);
            }else{
                client.publish(topic, message);
            }
        });
    }
}

export function susbscribeLaneSuccess(self) {
    initTopics();
    if(initOk){
        client = mqtt.connect({
            host: mqttConf.host,
            port: mqttConf.port,
            username: mqttConf.username,
            password: mqttConf.password,
            rejectUnauthorized: mqttConf.rejectUnauthorized,
            protocol: mqttConf.protocol
        });

        errorHandler(client);

        client.subscribe(topicLaneSuccess)

        client.on('message', function () {
            self.$parent.showLoader = false;
            self.$router.push(localStorage.getItem("DKC_base") + "/paymentdone");
            client.end()
        })
    }
}

export function susbscribeReset(self) {
    initTopics();
    if(initOk){
        client = mqtt.connect({
            host: mqttConf.host,
            port: mqttConf.port,
            username: mqttConf.username,
            password: mqttConf.password,
            rejectUnauthorized: mqttConf.rejectUnauthorized,
            protocol: mqttConf.protocol
        });

        errorHandler(client);

        client.subscribe(topicReset)

        client.on('message', function () {
            clearCacheExceptCustomer(self);
            clearBasket();
            client.end()
        })
    }
}

export function unsusbscribeReset() {
    initTopics();
    if(initOk){
        client = mqtt.connect({
            host: mqttConf.host,
            port: mqttConf.port,
            username: mqttConf.username,
            password: mqttConf.password,
            rejectUnauthorized: mqttConf.rejectUnauthorized,
            protocol: mqttConf.protocol
        });

        errorHandler(client);

        client.unsubscribe(topicReset)
    }
}

function getTopic(type) {
    var topic;
    switch (type) {
        case "SCAN_POSTER":
            topic = topicScanPoster;
            break;
        case "SCAN_PRODUCT":
            topic = topicScanProduct;
            break;
        case "PAYMENT_DONE":
            topic = topicPaymentDone;
            break;
        case "BACKOFFICE_APPROVE":
            topic = topicBackofficeApprove;
            break;
        case "BACKOFFICE_REJECT":
            topic = topicBackofficeReject;
            break;
        case "SCAN_LANE":
            topic = topicScanLane;
            break;
        case "PAYMENT_LIST":
            topic = topicPaymentList;
            break;
        case "SELECT_PAYMENT":
            topic = topicSelectPayment;
            break;
    }
    return topic;
}

function errorHandler(client){
    client.stream.on('error', function (err) {
        if(err){
            client.end(true);
            app.appContext.showAlert(app.appContext.$t('errorOnConnectMQTT'),"error");
        }
    });
}