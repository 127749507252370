<template>
    <div class="ProductBloc">

        <div v-for="product in products" :key="product.id" class="productCard cardTemplate row no-gutters">

            <div style="float:left;" :class="{  'col-3' : !isModalCrossSell,
                                                'col-5' : isModalCrossSell,
                                                'col-md-2' : !isModalCrossSell,
                                                'col-md-3' : isModalCrossSell,
                                                'align-self-center' : true}">
                <img style="border:0" class="img-thumbnail imageProduct" :src="img_url + product.image" />
            </div>

            <div :class="{  'product' : true,
                            'col-9' : !isModalCrossSell,
                            'col-7' : isModalCrossSell,
                            'col-md-10' : !isModalCrossSell,
                            'col-md-9' : isModalCrossSell,
                            'row' : true,
                            'no-gutters' : true}">
                <div class="nameProduct col-12 align-self-center no-gutters" v-if="getLang() == 'fr'">{{product.nameFr}}</div>
                <div class="nameProduct col-12 align-self-center no-gutters" v-else>{{product.nameEn}}</div>
                <div class="col-12 row align-self-end no-gutters product-info">
                    <p class="priceProduct">{{formatPrice(product.price.toFixed(2))}}</p>
                    <p class="product-qty" v-if="product.qty">x{{product.qty}}</p>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {isNotNull} from "@/assets/js/Utils"
    var utils = require('@/assets/js/Utils');

    export default {
        name: 'ProductBloc',
        props:{
            'scannedProduct' : Object,
            'isCrossSell' : Boolean,
            'basketProduct': Object
        },
        data:function() {
            return{
                products:[],
                isModalCrossSell: false,
                img_url: utils.getConfig().url_img
            }
        },
        created(){
            if(this.scannedProduct){
                this.products.push(this.scannedProduct);
            }
            if(this.basketProduct){
                this.basketProduct.product.qty = this.basketProduct.qty;
                this.products.push(this.basketProduct.product);
            }
            if(isNotNull(this.$props.isCrossSell) && this.$props.isCrossSell){
                this.isModalCrossSell = true;
            }
        },
        methods:{
            formatPrice(price) {
                price = parseFloat(price);
                return utils.formatPrice(price);
            },
            getLang(){
                return this.$root.$i18n.locale;
            }
        },
        watch:{
            scannedProduct: function() {
                this.products = [];
                this.products.push(this.scannedProduct);
            },
            basketProduct: function() {
                this.products = [];
                this.basketProduct.product.qty = this.basketProduct.qty;
                this.products.push(this.basketProduct.product);
            }
        }
    }
</script>
<style>
    .product-info{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .product-qty{
        font-weight: bold;
        margin: 0;
    }
</style>