<template>
    <div class="basket-by-keyword">
        <half-circle-spinner
            :animation-duration="1000"
            :size="60"
            v-if="loading"
            color="#ff1d5e"/>
        <div v-else class="basket">
            <h4 class="basket-by-keyword-title">{{$t("food.backoffice.basketHeader", { keyword: $route.params.keyword })}}</h4>
            <h4 class="basket-by-keyword-title keyword">{{$route.params.keyword}}</h4>
            <p class="nbr-products" v-html="$tc('food.backoffice.basketNumberOfProducts',nbrProducts, { nbrProducts: nbrProducts })"></p>
            <p class="creation-date">{{$t("food.backoffice.creationDate")}}: {{basketToShow.creationDate}}</p>
            <div class="products">
                <ProductBloc v-for="(basketProduct, key) in sortedProducts" :key="key" :basketProduct="basketProduct"/>
            </div>
        </div>
        <div class="footer">
            <div class="footer-infos">
                <div class="back-to-keywords" @click="$router.go(-1)">{{$t('food.backoffice.backToKeywords')}}</div>
                <div class="basket-amount">
                    <span>{{$t("food.backoffice.total")}} : </span>
                    <strong v-if="nbrProducts>0">{{formatPrice(basketToShow.totalAmount.toFixed(2))}}</strong>
                    <strong v-else>{{formatPrice(0.00)}}</strong>
                </div>
            </div>
            <div class="actions">
                <div class="action disapprove" @click="reject()" :style="{backgroundImage: `url('${require('@/assets/img/disapprove.png')}')`}"></div>
                <div class="action approve" @click="approve()" :style="{backgroundImage: `url('${require('@/assets/img/approve.png')}')`}"></div>
            </div>
        </div>
    </div>
</template>
<script>
import {getBasketByKeyword} from "@/assets/js/Food/BackofficeBasketList"
import ProductBloc from "@/components/Common/ProductBloc"
import { HalfCircleSpinner } from 'epic-spinners'
import {isNotNull, getConfig, formatPrice} from "@/assets/js/Utils"
var MQTT = require("@/assets/js/Food/MqttService");


export default {
    components: { HalfCircleSpinner, ProductBloc },
    data(){
        return{
            basketToShow: {
                "id": 2012,
                "creationDate": "23/07/2020 13:02",
                "totalAmount": 5.50,
                "keyword": "Smile",
                "basketProducts": [
                    {
                        "id": 7633,
                        "product": {
                            "id": 11,
                            "nameFr": "Eau",
                            "nameEn": "Water",
                            "price": 1.1,
                            "image": "978020137965.jpg",
                            "category": {
                                "id": 3,
                                "nameFr": "Nourriture",
                                "nameEn": "Food",
                                "image": "category2.jpg"
                            }
                        },
                        "qty": 1
                    },
                    {
                        "id": 7634,
                        "product": {
                            "id": 10,
                            "nameFr": "Soda",
                            "nameEn": "Soda",
                            "price": 1.3,
                            "image": "978020137964.jpg",
                            "category": {
                                "id": 3,
                                "nameFr": "Nourriture",
                                "nameEn": "Food",
                                "image": "category2.jpg",
                            }
                        },
                        "qty": 2
                    },
                    {
                        "id": 7635,
                        "product": {
                            "id": 9,
                            "nameFr": "Snack",
                            "nameEn": "Snack",
                            "price": 0.9,
                            "image": "978020137963.jpg",
                            "category": {
                                "id": 3,
                                "nameFr": "Nourriture",
                                "nameEn": "Food",
                                "image": "category2.jpg"
                            }
                        },
                        "qty": 2
                    }
                ]
            },
            loading: true
        }
    },
    created(){
        let demoId = this.$route.params.demoId
        getBasketByKeyword(this.$route.params.keyword, demoId,basket => {
            if(isNotNull(basket)){
                sessionStorage.removeItem("basket-keyword-error");
                if(basket.state == "FINISH"){   
                    this.basketToShow = basket;
                }
            }
            this.loading = false;
        })
    },
    methods: {
        formatPrice(price) {
            return formatPrice(price);
        },
        approve(){
            /*let approvedKeywords = JSON.parse(sessionStorage.getItem("DKC_approved_keywords")) || [];
            approvedKeywords.push(this.$route.params.keyword);
            sessionStorage.setItem("DKC_approved_keywords", JSON.stringify(approvedKeywords));
            */
            let mqttMessage = {
                encodedTicketId: this.basketToShow.complexId
            }
            MQTT.publishMessage("BACKOFFICE_APPROVE", JSON.stringify(mqttMessage), () => {
                this.$router.go(-1)
            });
        },
        reject(){
            let mqttMessage = {
                encodedTicketId: this.basketToShow.complexId
            }
            MQTT.publishMessage("BACKOFFICE_REJECT", JSON.stringify(mqttMessage), () => {
                this.$router.go(-1)
            });
        }
    },
    computed:{
        nbrProducts(){
            if(this.basketToShow != null){
                return Object.values(this.basketToShow.basketProducts).reduce((t, {qty}) => t + qty, 0)
            }
            return null; 
        },
        sortedProducts(){
            if(this.basketToShow != null){
                return this.basketToShow.basketProducts.sort((p1, p2) => {
                    return p2.product.price - p1.product.price
                })
            }
            return null
        }
    }
}
</script>