import {getConfig, isNotNull} from "../Utils";

var app = getConfig();

export function getBasketByKeyword(keyword, demoId, callback){
    var path = "/basket/getByKeyword";
    var url = app.DKW_URL + path;
    var params = "?keyword=" + keyword + "&demoId=" + demoId;
    var request = new XMLHttpRequest();
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = request.response
                if(isNotNull(response))
                    callback(JSON.parse(response));
                else
                    callback(null);
            }else{
                callback(null)
            }
        }
    };
    request.open('GET', url + params, true);
    request.send();
}

export function getAllKeywords(callback){
    var path = "/basket/get/all/keywords";
    var url = app.DKW_URL + path;
    var request = new XMLHttpRequest();
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = request.response
                callback(JSON.parse(response));
            }else{
                app.appContext.showAlert(app.appContext.$t('errorGetTicket'),"error");
            }
        }
    };
    request.open('GET', url, true);
    request.send();
}

export function getBasketsByDemoId(demoId, callback){
    var path = "/basket/get/all/baskets";
    var url = app.DKW_URL + path;
    var params = "?demoId=" + demoId;
    var request = new XMLHttpRequest();
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = request.response
                callback(JSON.parse(response));
            }else{
                app.appContext.showAlert(app.appContext.$t('errorGetTicket'),"error");
            }
        }
    };
    request.open('GET', url + params , true);
    request.send();
}