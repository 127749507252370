var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ProductBloc"},_vm._l((_vm.products),function(product){return _c('div',{key:product.id,staticClass:"productCard cardTemplate row no-gutters"},[_c('div',{class:{  'col-3' : !_vm.isModalCrossSell,
                                            'col-5' : _vm.isModalCrossSell,
                                            'col-md-2' : !_vm.isModalCrossSell,
                                            'col-md-3' : _vm.isModalCrossSell,
                                            'align-self-center' : true},staticStyle:{"float":"left"}},[_c('img',{staticClass:"img-thumbnail imageProduct",staticStyle:{"border":"0"},attrs:{"src":_vm.img_url + product.image}})]),_c('div',{class:{  'product' : true,
                        'col-9' : !_vm.isModalCrossSell,
                        'col-7' : _vm.isModalCrossSell,
                        'col-md-10' : !_vm.isModalCrossSell,
                        'col-md-9' : _vm.isModalCrossSell,
                        'row' : true,
                        'no-gutters' : true}},[(_vm.getLang() == 'fr')?_c('div',{staticClass:"nameProduct col-12 align-self-center no-gutters"},[_vm._v(_vm._s(product.nameFr))]):_c('div',{staticClass:"nameProduct col-12 align-self-center no-gutters"},[_vm._v(_vm._s(product.nameEn))]),_c('div',{staticClass:"col-12 row align-self-end no-gutters product-info"},[_c('p',{staticClass:"priceProduct"},[_vm._v(_vm._s(_vm.formatPrice(product.price.toFixed(2))))]),(product.qty)?_c('p',{staticClass:"product-qty"},[_vm._v("x"+_vm._s(product.qty))]):_vm._e()])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }